.lang-ko .ant-popover {
    font-weight: 300;
}

.lang-ko .ant-card,
.lang-ko .ant-form {
    letter-spacing: -0.4px;
}

body {
    color: #3c3d3f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    color: #3c3d3f;
    letter-spacing: -0.5px;
}

.ant-form,
.ant-radio-wrapper {
    color: #3c3d3f;
}

// .ant-form input.ant-input {
//     padding: 10px 12px;
//     height: 40px;
// }

.ant-form .ant-input,
.ant-form .group-display-container,
.ant-form .ant-select {
    font-weight: 300;
}

.ant-form .ft-bold {
    font-weight: bold;
}

.ant-form input[type='password'].ant-input {
    font: 28px Verdana, sans-serif;
}

.ant-form .ant-select-lg .ant-select-selection--single {
    height: 40px;
}

.ant-form .ant-select-lg {
    font-size: 14px;
    /* font-weight: 100; */
}

// .ant-form fieldset {
//     margin-bottom: 1em;
// }

.ant-form fieldset > legend {
    border: none;
    color: black;
    font-weight: bold;
    margin-bottom: 16px;
}

@media (max-width: 575px) {
    .ant-form-item-label {
        white-space: normal;
    }
}

.ant-form-item-label label {
    color: #3c3d3f;
    // font-weight: bold;
}

.ant-form fieldset .ant-form-item-label label {
    font-weight: normal;
    font-size: 12px;
}

/* .ant-form-item-required.cp-required-indicator::after {
    content: '\2022';
    display: inline-block;
    line-height: 1;
    font-size: 14px;
    vertical-align: top;
    color: #008C00;
} */

.ant-form-item-required::before {
    display: none;
}
.ant-form-item-label .cp-required-indicator::after {
    content: '\2022';
    display: inline-block;
    line-height: 1;
    font-size: 16px;
    vertical-align: text-top;
    color: #008C00;
    margin-left: 3px;
}

.has-error .ant-form-explain{
    background: url('./exclamation.svg') no-repeat left 3px;
    padding-left: 18px;
}

.ant-form-hide-required-mark .ant-form-item-label .cp-required-indicator::after {
    display: none;
}

.has-error .ant-input,
.has-error .ant-input:hover,
.has-error .ant-input:not([disabled]):hover,
.has-error .ant-input:focus,
.has-error .ant-select .ant-select-selection {
    border-color: #f56112;
    border-width: 1px;
}

.has-error .ant-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 97, 18, 0.2);
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
    color: #f56112;
    font-size: 12px;
    margin-top: 5px;
}

/*.has-error.has-feedback:after {
    content: '\E62C';
    line-height: normal;
    height: 20px;
    width: 20px;
    top: auto;
    bottom: 0;
    font-size: 18px;
    color: #f56112;
}*/

/* Button */
.ant-btn.ant-btn-lg {
    height: 48px;
    padding: 0px 15px;
    min-width: 166px;
    font-weight: bold;
}
@media (max-width: 575px) {
    .ant-btn.ant-btn-lg {
        min-width: 120px;
    }
}
.ant-btn.ant-btn-sm {
    height: 24px;
}
.ant-btn.xs {
    height: 24px;
}
.ant-btn.sm {
    height: 28px;
    font-size: 12px;
    min-width: 56px;
}
a.ant-btn.sm {
    line-height: 28px;
}
.ant-btn.md {
    height: 32px;
}
.ant-btn.lg {
    min-width: 120px;
    height: 40px;
    font-weight: bold;
}
.ant-btn.xl {
    height: 44px;
}
a.ant-btn {
    line-height: 36px;
}

.ant-btn {
    border-radius: 100px;
    // height: 36px;
    border-color: #aeb0b4;
    color: #3c3d3f;
    letter-spacing: -0.4px;
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: #aeb0b4;
    color: #3c3d3f;
    box-shadow: 0 3px 12px 0 rgba(117, 119, 123, 0.2);
}

.ant-btn-primary {
    border-color: #346aff;
    background-color: #346aff;
    color: white;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    border-color: #346aff;
    background-color: #346aff;
    color: white;
    box-shadow: 0 3px 12px 0 rgba(45, 77, 167, 0.3);
}
.ant-btn-background-ghost.ant-btn-primary,
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
    border-color: #346aff;
    color: #346aff;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
    border-color: #346aff;
    color: #346aff;
}

.ant-btn.btn-link.ant-btn-clicked:after {
    animation: none;
}

.ant-btn.btn-link:hover,
.ant-btn.btn-link:focus {
    box-shadow: none;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #346aff;
    border-color: #346aff;
}


.ant-tooltip-inner {
    background-color: #008C00;
    font-weight: lighter;
    padding: 16px;
    font-size: 12px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow,
.ant-tooltip-placement-bottom .ant-tooltip-arrow{
    &:before {
        background: #008C00;
    }
}

.ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover,
.ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #3c3d3f;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: #3c3d3f;
}

.ant-menu-vertical .ant-menu-item-selected > a,
.ant-menu-sub .ant-menu-item-selected > a {
    color: #008C00 !important;
    font-weight: bold;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #008C00 !important;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
    background: linear-gradient(to right, #3c3d3f, #3c3d3f);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected:hover,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-horizontal > .ant-menu-submenu:hover {
    border-bottom-color: #008C00;
    color: #3c3d3f !important;
}

.ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
}
.ant-menu-vertical .ant-menu-item-selected > a,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
    font-weight: bold;
}

.ant-menu-submenu-title:hover {
    color: #3c3d3f;
}

.ant-dropdown-menu,
.ant-select-dropdown {
    border-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(117, 119, 123, 0.3);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    padding: 12px 20px;
}

.ant-dropdown-menu-item:first-child,
.ant-dropdown-menu-submenu-title:first-child,
.ant-dropdown-menu-item:first-child > a,
.ant-dropdown-menu-submenu-title:first-child > a {
    border-radius: 8px 8px 0px 0px;
}

.ant-dropdown-menu-item:last-child,
.ant-dropdown-menu-submenu-title:last-child,
.ant-dropdown-menu-item:last-child > a,
.ant-dropdown-menu-submenu-title:last-child > a {
    border-radius: 0px 0px 8px 8px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: white;
}

.ant-input {
    border-radius: 8px;
}

.align-right .ant-input {
    text-align: right;
}

.ant-input::placeholder,
textarea.ant-input::placeholder,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    font-weight: 200;
    font-size: 14px;
    color: #808286;
    letter-spacing: -0.4px;
}
.min-placeholder .ant-input::placeholder,
.min-placeholder textarea.ant-input::placeholder,
.min-placeholder .ant-select-selection__placeholder,
.min-placeholder .ant-select-search__field__placeholder {
    font-size: 10px;
    line-height: 24px;
}

.ant-checkbox-inner {
    height: 20px;
    width: 20px;
    border: 2px solid #aeb0b4;
}

.ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1);
    left: 5px;
    height: 12px;
    width: 6px;
    border-color: #d3d3d3 !important;
    opacity: 1 !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #fff !important;
    transform: rotate(45deg) scale(1);
}

.ant-checkbox-checked:after {
    border-radius: 50%;
}

.ant-checkbox-wrapper .ant-checkbox-inner:after {
    top: 1px;
}

.ant-checkbox-wrapper.sm .ant-checkbox-inner {
    height: 20px;
    width: 20px;
}

.ant-checkbox-wrapper.sm .ant-checkbox-inner:after {
    left: 5px;
    top: 2px;
    height: 11px;
    width: 6px;
}

.ant-checkbox-wrapper.sm .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #fff;
}

/* .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.ant-checkbox:hover .ant-checkbox-inner, 
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #346aff;
}
 */

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #346aff;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    border: 2px solid rgb(231, 232, 233);
    background-color: #fff;
}

.ant-input {
    color: #3c3d3f;
}

.ant-input-disabled {
    background-color: #f7f7f7;
    border: 1px solid #dfe0e2;
    color: #3c3d3f;
}

.ant-pagination {
    font-size: 12px;
}

.ant-pagination .ant-pagination-item {
    border-color: #cfd0d2;
}

.ant-pagination .ant-pagination-item-active {
    background-color: #008C00;
    border-color: #008C00;
}

.ant-pagination .ant-pagination-item-active a {
    color: white;
}

.ant-popover {
    .ant-popover-inner {
        background-color: #7b3c94;
        font-size: 12px;
    }

    .ant-popover-inner-content {
        color: white;
    }
    .ant-popover-arrow {
        background-color: #7b3c94;
        border-color: #7b3c94 !important;
    }
}

.ant-select-selection {
    border-radius: 8px;
}

form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear,
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear {
    right: 15px;
}

form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
form .has-feedback > .ant-select .ant-select-selection-selected-value {
    padding-right: 22px;
}

.ant-tabs.affiliate.large-tab-title .ant-tabs-nav-container {
    font-size: 16px;
}

.ant-tabs.affiliate .ant-tabs-nav .ant-tabs-tab-active {
    border-left: 1px solid #dfe0e2;
    border-right: 1px solid #dfe0e2;
    border-bottom: 2px solid white;
}

.ant-tabs.affiliate .ant-tabs-nav .ant-tabs-tab {
    font-weight: bold;
    color: #808286;
}

.ant-tabs.affiliate .ant-tabs-nav .ant-tabs-tab-active {
    color: #3c3d3f;
}

.ant-tabs.affiliate .ant-tabs-ink-bar {
    top: 0px;
    bottom: auto;
    background-color: #008C00;
}

.ant-tabs.affiliate.non-tabs-content .ant-tabs-bar {
    margin-bottom: 0;
}

.ant-tabs {
    color: #808286;
}
.ant-tabs-bar {
    margin-bottom: 32px;
}
.ant-tabs .ant-tabs-ink-bar {
    background-color: #008C00;
}
.ant-tabs .ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
    color: #3c3d3f;
}
.ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: bold;
}

.ant-radio-group-large .ant-radio-inner,
.ant-radio-wrapper.lg .ant-radio-inner {
    height: 20px;
    width: 20px;
    border-width: 2px;
    border-color: #aeb0b4;
}

.ant-radio-group-large .ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper.lg .ant-radio-checked .ant-radio-inner {
    background-color: #346aff;
    border-color: #346aff;
}

.ant-radio-group-large .ant-radio-inner:after,
.ant-radio-wrapper.lg .ant-radio-inner:after {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    margin-top: -4px;
    margin-left: -4px;
    background-color: #dfe0e2;
    transform: none;
    opacity: 1;
}

.ant-radio-group-large .ant-radio-checked .ant-radio-inner:after,
.ant-radio-wrapper.lg .ant-radio-checked .ant-radio-inner:after {
    background-color: white;
}

.ant-radio-group-large .ant-radio-wrapper .ant-radio ~ span,
.ant-radio-wrapper.lg .ant-radio-wrapper .ant-radio ~ span {
    line-height: 24px;
    vertical-align: text-bottom;
}

.ant-radio-group .ant-radio-wrapper:not(:last-child) {
    margin-bottom: 14px;
}

.ant-table-filter-dropdown {
    min-width: 110px;

    .ant-radio-wrapper .ant-radio ~ span {
        line-height: 20px;
        vertical-align: text-bottom;
    }

    .ant-radio-inner {
        height: 20px;
        width: 20px;
        border-width: 1px;
        border-color: #aeb0b4;

        &:after {
            height: 7px;
            width: 7px;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: #dfe0e2;
            opacity: 1;
        }
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: #346aff;
        border-color: #346aff;

        &:after {
            background-color: white;
        }
    }

    .ant-table-filter-dropdown-link {
        color: #3c3d3f;
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
        background-color: transparent;
        color: #3c3d3f;
    }

    .ant-table-filter-dropdown-btns {
        padding: 8px 15px;
    }
}

.ant-table .ant-table-thead > tr > th {
    background-color: white;
    font-size: 12px;
    font-weight: bold;
}

.ant-table tbody {
    font-size: 12px;
    font-weight: normal;
}

.ant-table tr.ant-table-row:nth-child(odd) {
    background-color: #fafafa;
}

.ant-table-wrapper .ant-pagination-item {
    min-width: 30px;
    height: 32px;
}

.ant-table-wrapper .ant-pagination-prev,
.ant-table-wrapper .ant-pagination-next,
.ant-table-wrapper .ant-pagination-jump-prev,
.ant-table-wrapper .ant-pagination-jump-next {
    min-width: 30px;
}

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: #008C00;
}

.ant-upload.ant-upload-select-picture-card {
    border: 0 none;
}

.ant-alert-warning {
    background: #fbe5d9;
    border: 0 none;
}
.ant-alert-with-description .ant-alert-message {
    color: #f56112;
    font-size: 14px;
}
.ant-alert-with-description {
    color: #3c3d3f;
}
.ant-alert-with-description.ant-alert-no-icon {
    padding: 24px;
}

.ant-alert-error {
    background: #fbe5d9;
    border: 0 none;
}

.ant-alert-success {
    background-color: #e4f4ea;
    border: none;
}

.ant-modal .ant-modal-footer button + button {
    margin-left: 12px;
}

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
    background: #346aff;
    color: #fff;
    border: 1px solid transparent;
}
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover {
    background: #346aff;
}

.ant-select-dropdown.hide-disabled-item .ant-select-dropdown-menu-item-disabled {
    display: none;
}

.loading-spinner .ant-spin-dot i {
    background-color: #008C00;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 12px;
    color: #3c3e41;
    font-weight: bold;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 12px;
    color: #3c3e41;
}

.ant-notification-notice {
    box-shadow: 0 3px 12px 0 rgba(117, 119, 123, 0.17);
    border-radius: 8px;
    padding: 16px;
}

.ant-notification-notice .ant-notification-notice-icon > svg {
    height: 22px;
}

.ant-notification-notice-icon-success {
    color: #008C00;
}

.ant-notification-notice-icon-info {
    color: #346aff;
}

.ant-notification-notice-icon-warning {
    color: #f4c822;
}

.ant-notification-notice-icon-error {
    color: #f56112;
}

.ant-modal-content {
    border-radius: 0px;
}

.ant-spin {
    color: white;
}

.ant-spin .ant-spin-dot i {
    background-color: #008C00;
}

.affiliate-modal {
    .ant-modal-footer {
        padding: 20px 48px 40px 48px;
        border: 0 none;
        text-align: center;

        .ant-btn {
            min-width: 166px;
            height: 48px;
            font-size: 16px;
        }
    }

    .ant-modal-body {
        padding: 8px 48px;
    }

    .ant-modal-header {
        padding: 20px 48px;
        padding-bottom: 20px;
        border: 0 none;
    }

    .ant-modal-title {
        line-height: 40px;
        font-size: 28px;
        font-weight: bold;
        color: #3c3d3f;
    }
}

form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 0;
}

.ant-list-bordered {
    border-radius: 8px;
}


.ant-form .ant-form-item .ant-form-item-label .ant-form-item-required {
    &:after{
        display: inline-block;
        margin-left: 2px;
        color: #00AF00;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: '*';
    }
}


@media (max-width: 480px) {
   
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
      min-height: auto;
    }
    
  }
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;