.cp-steps.ant-steps-dot {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.lang-ko .cp-steps.ant-steps, .lang-ko .cp-steps .ant-steps-item-icon, .lang-ko .ant-steps-item-icon {
    font-family: 'NotoSansCJKKr', Noto, Roboto, Helvetica, 'Apple Sd Gothic', 'Malgun Gothic', sans-serif;
}

.cp-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
    height: 46px;
    width: 46px;
    line-height: 46px;
    left: -20px;
    top: -17px;
}

.cp-steps.ant-steps-dot.small .ant-steps-item-icon .ant-steps-icon-dot {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 14px;
    left: -13px;
    top: -11px;
}

.cp-steps.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: -20px;
}

.cp-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
    top: -17px;
}

.cp-steps.ant-steps-dot.small .ant-steps-item:first-child .ant-steps-icon-dot {
    left: -13px;
}

.cp-steps.ant-steps-dot.small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
    top: -11px;
}

.cp-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
.cp-steps.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon-dot,
.cp-steps.ant-steps-dot .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon-dot {
    font-weight: 500;
}

.cp-steps.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon-dot,
.cp-steps.ant-steps-dot .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon-dot {
    background-color: #dfe0e2;
    color: #3c3d3f;
}

.cp-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: #008C00;
}


.cp-steps .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title, 
.cp-steps .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: #808286;
}

.cp-steps .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: #008C00;
}

.cp-steps.ant-steps-dot .ant-steps-item-tail:after {
    height: 8px;
}

.cp-steps.ant-steps-label-vertical .ant-steps-item-content {
    margin-top: 25px;
}


.cp-steps > .ant-steps-item-container > .ant-steps-item-tail:after,
.cp-steps .ant-steps-item-process> .ant-steps-item-container  > .ant-steps-item-tail:after,
.cp-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after,
.cp-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background: #f7f7f7;
}

.cp-steps .ant-steps-item-title {
    font-size: 14px;
}
.cp-steps.small .ant-steps-item-title {
    font-size: 12px;
}

