
.summary-panel {
    /* height: 112px; */
    position: relative;
    border: 1px solid #DFE0E2;
    border-radius: 8px;
    padding: 20px 24px 0 24px;
}

.summary-panel .metric-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.summary-panel .metric-list > .metric-box {
    flex: 1 1 auto;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.summary-panel .metric-list > .metric-box:not(:last-child) {
    margin-right: 30px;
}

.summary-panel .metric-list > .metric-box .metric {
    font-size: 14px;
    color: #808286;
    margin-bottom: 6px;
    font-weight: 300;
    white-space: nowrap;
}

.summary-panel .metric-list > .metric-box .metric-value {
    font-size: 28px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: #3C3D3F;
    letter-spacing: -0.31px;
}

.summary-panel .last-update {
    position: absolute;
    left: 8px;
    top: -32px;
    color: #808286;
    font-size: 12px;
}