.lang-ko .unsubscribe-affiliate {
    letter-spacing: -0.4px;
    font-family: NotoSansCJKKr, sans-serif;
}

.unsubscribe-affiliate {
    font-weight: 300;
}

.unsubscribe-affiliate section {
    margin-bottom: 40px;
}

.unsubscribe-affiliate section > h3 {
    margin-bottom: 24px;
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;